/* rgba(247, 248, 250, 0.8) */
/* rgba(254, 59, 31, 0.9) */
/* rgba(180, 48, 7, 1) */
html {
}
body {
	background: linear-gradient(
		135deg,
		rgba(4, 46, 113, 1) 0%,
		rgba(6, 20, 41, 1) 100%
	);
	background-attachment: fixed;

	color: rgba(0, 0, 0, 0.9);
	font-size: 14px;
	line-height: 1.6em;
	margin: 0;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
#root {
	height: 100vh;
	width: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
